import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div id="header-intro" className="col-md-12 intro-text">
                <h2>Download Now</h2>
                <div class="app-icons">
                  <a href="https://apps.apple.com/in/app/infinbuy/id6471233599">
                    <img
                      class="apple"
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                      alt="Download on the App Store" />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.infinbuy'>
                    <img
                      class="android"
                      alt='Get it on Google Play'
                      src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                  </a>
                </div>
                <h1 style={{height:"5px"}}></h1>
                <h1 id="header-intro-h1">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

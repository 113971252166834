import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    isAndroid,
    isIOS,
} from 'react-device-detect';

const DeeplinkRedirect = () => {
    const history = useNavigate();

    useEffect(() => {
        const redirectUser = () => {
            if (window.location.pathname.startsWith('/deeplink')) {
                if (isAndroid) {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.infinbuy';
                } else if (isIOS) {
                    window.location.href = 'https://apps.apple.com/in/app/infinbuy/id6471233599';
                } else {
                    // Default to homepage if the platform is not recognized
                    history('/');
                }
            }
        };

        redirectUser();
    }, [history]);

    return null;
};

export default DeeplinkRedirect;